import React from "react";
import { Link } from "react-router-dom";
import {} from "react-icons/fa";
import { BiMenu, BiWallet } from "react-icons/bi";

function Layout({ children }) {
  return (
    <div>
      <header className="min-h-[80px] z-30 w-full text-white">
        <div className="mx-auto flex justify-between items-center py-4 px-6">
          <Link className="flex flex-row gap-6 items-center" to="/">
            <img src="/images/logo.svg" className="w-10 h-10" alt="" />
            <p className=" text-white text-base font-semibold  ">
              PulseChain Bridge
            </p>
          </Link>
          <nav className="flex flex-row items-center space-x-4">
            <div className=" hidden sm:flex sm:flex-row gap-4 justify-between ">
              <button className="flex flex-row gap-2 outline-none rounded-md cursor-pointer text-white bg-fuchsia-600 px-6 py-2 text-sm font-medium capitalize border-none ">
                <BiWallet className="h-6 w-6" />
                Connect Wallet
              </button>
            </div>
            <BiMenu className=" sm:hidden  h-6 w-6" />
          </nav>
        </div>
      </header>
      <main>{children}</main>
      <footer className="w-full relative flex flex-col h-20 items-center justify-center pt-4">
        <div className="flex flex-row gap-8 items-center justify-center">
          <button className="w-fit  p-4 flex flex-col items-center justify-center bg-slate-700 rounded-full">
            <img
              src="/images/discord.svg"
              className=" fill-white h-4 w-4 "
              alt="discord"
            />
          </button>
          <button className="w-fit  p-4 flex flex-row items-center justify-center bg-slate-700 rounded-full">
            <img
              src="/images/twt.svg"
              className=" fill-white h-4 w-4 "
              alt="twt"
            />
          </button>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
