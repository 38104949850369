import React, { useState } from "react";
// import RainbowCard from "./components/ui/Cards";
// import cardData from "./components/data/carddata"; // Import the data
// import Hero from "./components/ui/Hero";
import Modal from "./components/ui/Modal";

function App() {
  const [openMenu, setIsOpenMenu] = useState(false);
  const toggleMenu = () => {
    setIsOpenMenu(!openMenu);
  };

  return (
    <>
      <div className="flex w-full items-start justify-center flex-row  min-h-svh h-full">
        <div className="flex flex-col w-fit h-fit bg-[#191b1a] rounded-2xl shadow border p-4 border-black">
          <p className="text-[#dfe4ed] text-sm font-normal">From</p>
          <div>
            <div
              className=" flex flex-col items-center w-fit h-fit gap-3 "
              action=""
            >
              <div className=" bg-[#232523] w-full flex flex-col gap-1 p-2 rounded ">
                <div className="flex flex-row justify-between w-full">
                  <div className="flex flex-row items-center min-h-14 p-2 gap-2">
                    <img
                      src="/images/eth.svg"
                      className=" w-7 h-7"
                      alt="Ethereum"
                    />
                    <p className="text-[#dfe4ed] w-fit text-sm font-bold">
                      Ethereum
                    </p>
                  </div>
                  <div className="flex flex-row gap-1 min-h-14 p-2 items-center w-fit">
                    <p className=" w-fit font-normal text-xs text-[#dfe4ed]  ">
                      Balance: 0.0 WPLS
                    </p>
                    <p className=" w-fit text-[#ff00f7]/60  text-sm font-semibold  ">
                      Max
                    </p>
                  </div>
                </div>
                <div className=" flex flex-row items-center justify-stretch ">
                  <input
                    className=" mr-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none min-w-48 w-full bg-transparent outline-none "
                    type="number"
                    name=""
                    id=""
                    placeholder="0.0"
                  />
                  <img
                    src="/images/logo.svg"
                    className="w-8 h-8 mr-1"
                    alt="PulseChain Logo"
                  />
                  <p className="text-[#dfe4ed] text-lg font-bold">WPLS</p>
                </div>
              </div>
              <div className=" bg-[#232523] w-full flex flex-col gap-1 p-2 rounded ">
                <div className="flex flex-row justify-between  w-full">
                  <div className="flex flex-row items-center gap-2">
                    <img
                      src="/images/logo.svg"
                      className=" w-7 h-7"
                      alt="Pulsechain"
                    />
                    <p className="text-[#dfe4ed] w-fit text-sm font-bold">
                      PulseChain
                    </p>
                  </div>
                  <div className="flex flex-row gap-1 w-fit">
                    <p className=" w-fit font-normal text-xs text-[#dfe4ed]  ">
                      Balance: 0.0 PLS
                    </p>
                  </div>
                </div>
                <div className="flex-col flex gap-2  justify-between">
                  <div className="flex flex-row items-center justify-between">
                    <p className=" text-[#dfe4ed] text-sm font-normal ">
                      Bridge Fee
                    </p>
                    <p className=" text-[#dfe4ed] text-sm font-normal ">0%</p>
                  </div>
                  <p className=" text-[#dfe4ed] text-xs font-bold ">
                    Fee to bridge Ethereum may be different{" "}
                  </p>
                  <div className="flex flex-row items-end justify-between">
                    <p className=" text-[#dfe4ed] text-sm font-normal ">
                      Receive
                    </p>
                    <p className=" text-[#dfe4ed] text-sm font-normal ">
                      0.0 <span className=" text-lg font-bold ">PLS </span>
                    </p>
                  </div>
                  {/* <p className=" my-2 text-[#dfe4ed] text-sm font-normal ">
                    Receive PLS
                  </p>
                  <p className="my-2 text-[#dfe4ed] text-sm font-normal ">
                    Limits
                  </p> */}
                </div>
              </div>
              <div></div>
              <p className="text-[#858586] text-sm font-normal w-full text-center">
                v1.0.7
              </p>
              <button
                onClick={toggleMenu}
                className="w-96 flex justify-center text-center items-center h-12 py-4 bg-[#cc00c5]/70 rounded-lg text-base font-bold text-[#dfe4ed] "
              >
                Connect Wallet
              </button>
            </div>
          </div>
          <p className="text-[#858586] text-sm font-normal w-full text-center">
            Estimated to completion is 30 minutes
          </p>
        </div>
      </div>
      {openMenu ? (
        <div className="flex items-center">
          <Modal isToggled={openMenu} onToggle={setIsOpenMenu} />
          <div className="grey"></div>
        </div>
      ) : null}
    </>
  );
}

export default App;
